<template>
  <DebugConsole v-if="debugMode" />
  <web-login v-if="!noPassword" />
  <router-view v-if="auth || noPassword" />
</template>

<script>
import { ref, provide } from "vue";
import WebLogin from "./views/login/web-login.vue";
import DebugConsole from "./common/debug-console.vue";

export default {
  name: "main-app",
  setup() {
    const idle = ref(false);
    const filled = false;
    const profileFilled = ref(filled);
    const auth = ref(window.sessionStorage.auth);
    function updateAuth() {
      auth.value = true;
      window.sessionStorage.auth = true;
    }
    function updateIdle() {
      idle.value = !idle.value;
    }
    function updateProfileFilled(value) {
      profileFilled.value = value;
    }
    provide("profileFilled", { profileFilled, updateProfileFilled });
    provide("idle", { idle, updateIdle });
    provide("auth", { auth, updateAuth });

    return {
      auth,
      idle,
      currentUser: undefined,
    };
  },
  data() {
    let debugMode = false;
    let appVersion = "1.2.9";
    let appTitle = `Lify x PanopticAI ${appVersion}`;

    return {
      vitalSignObject: undefined,
      healthObject: undefined,
      host: location.host,
      appVersion: appVersion,
      appTitle: appTitle,
      debugMode: debugMode,
      debugMessage: "[ Your debug message ]",
      doneLoading: {
        faceDetectionView: false,
        system: true,
        scanning: true,
      },
    };
  },
  mounted() {
    if (this.debugMode) {
      console.log(`%c< ${this.appTitle} > DEBUG MODE`, "color: red");
    } else {
      console.log(`< ${this.appTitle} >`);
    }
  },
  computed: {
    noPassword() {
      return this.host.match(/^localhost:[\d]{1,4}/) || location.href.match(/^.*\/#\/report.*/);
    },
  },
  components: {
    WebLogin,
    DebugConsole,
  },
};
</script>

<style>
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
body::-webkit-scrollbar {
  display: none;
}
.start-content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.start-content::-webkit-scrollbar {
  display: none;
}
.start-content-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.start-content-container::-webkit-scrollbar {
  display: none;
}
.quiz {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.quiz::-webkit-scrollbar {
  display: none;
}
</style>