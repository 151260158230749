<template>
  <div>
    <LoadingScreen
      v-if="
        !Object.keys(this.$root.$data.doneLoading).every((key) => {
          return this.$root.$data.doneLoading[key];
        })
      "
    ></LoadingScreen>
    <!-- button navigation -->
    <div class="d-flex buttons-container justify-content-end">
      <locale-button class="locale-button" />

      <div class="buttons">
        <div :class="`switch-button ${starting ? 'white' : 'black'}`">
          <input class="switch-button-checkbox" type="checkbox" v-model="starting" />
          <label class="switch-button-label" for=""
            ><span class="switch-button-label-span">{{ t("button.off") }}</span></label
          >
        </div>
      </div>
    </div>

    <!-- popup user profile -->
    <user-profile-input
      v-if="form && showModal"
      :showModal="showModal"
      @updateProfile="updateProfile"
      :profileFilled="profileFilled"
      :form="form"
      @updateModal="updateModal"
    />

    <!-- video container -->
    <div id="video-container" ref="videoContainer">
      <!-- condition checking & instructions -->
      <div>
        <face-detection-view
          :enable="starting"
          :vsStatus="vsStatus"
          @finishMeasurement="finishMeasure"
          @updateTimeLeft="updateTimeLeft"
          @detectionResult="updateDetectionResult"
          @scanParameters="updateScanParameters"
          @startQuiz="startQuiz"
          :device="devices.length > 1 ? devices[cameraIndex].id : ''"
          @updateCountdown="updateCountdown"
          :countdown="countdown"
          @updateConditions="updateConditions"
          @uploadedScanResult="uploadedScanResult"
        />
      </div>
      <!-- 
      <img id="lify-logo" src="./assets/lify.svg" />
      <img id="panoptic-logo" src="./assets/panoptic_ai.svg" />
      <img id="fittery-logo" src="./assets/fittery.png" alt="">
      <img id="tda-logo" src="./assets/tda-logo.svg" alt=""> -->
    </div>
    <start-content
      :vsStatus="vsStatus"
      :countdown="countdown"
      :conditions="conditions"
      :enable="detectionResult"
      :showTC="showTC"
      @startMeasurement="startMeasurement"
      @removeProfile="removeProfile"
      @updateModal="updateModal"
      @updateShowTC="updateShowTC"
      v-if="vsStatus !== 'measuring' && starting && !showQuiz && !showModal"
    />
    <condition-checking
      :conditions="conditions"
      :countdown="countdown"
      v-if="vsStatus == 'measuring' && starting && !showQuiz && !showModal"
    />
    <img
      v-if="devices && devices.length > 1 && vsStatus !== 'measuring' && starting && !showQuiz && !showModal"
      :disabled="vitalSign !== undefined"
      class="btn camera-switch"
      @click="switchCamera"
      src="./assets/switch-camera.svg"
    />
    <quiz
      v-if="showQuiz"
      :scanFinished="scanFinished"
      :number="number"
      @displayResult="displayResult"
      @nextCard="nextCard"
    />
    <result
      v-if="showResult"
      :vitalSign="vitalSign"
      :recommend="recommend"
      :userProfile="form"
      @removeProfile="removeProfile"
      :scanResultId="scanResultId"
    />
    <circular-progress :timeLeft="getTimeLeft()" :radius="radius" :stroke="10" />
  </div>
</template>

<script>
import Logger from "../../common/logger.js";
import { ref, inject } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import api from "../../common/api.js";
import adapter from "webrtc-adapter";
import LoadingScreen from "./loading-screen.vue";
import FaceDetectionView from "./face-detection-view.vue";
import userProfileInput from "./user-profile-input.vue";
import { newUserManager } from "../../user/company-user-manager.ts";
import Quiz from "./quiz.vue";
import Result from "./result/result.vue";
import StartContent from "./start-content.vue";
import LocaleButton from "../sidebar/locale-button.vue";
import ConditionChecking from "./condition-checking.vue";
import CircularProgress from "./circular-progress.vue";
import { resetMovementArray } from "@/plugin/plugin_helpers.js";

export default {
  name: "App",
  props: {
    simulation: Boolean,
  },
  setup() {
    const { idle, updateIdle } = inject("idle");
    const { profileFilled, updateProfileFilled } = inject("profileFilled");
    const { t, locale } = useI18n();
    const router = useRouter();

    var currentUser = newUserManager.currentUser ? newUserManager.currentUser.currentUser : null;
    let vitalSign = null;
    let scanParameters = null;
    if (newUserManager.currentUser && newUserManager.currentUser.cloudInfo) {
      vitalSign = currentUser.lastVitalSigns;
      scanParameters = currentUser.lastHealth ? currentUser.lastHealth.scanParameters : null;
    } else {
      router.push("/");
    }

    return {
      t,
      locale,
      router,
      starting: ref(idle),
      stopping: ref(true),
      sysStatus: ref("standby"),
      vsStatus: ref("idle"),
      flask_fps: ref(),
      vitalSign: ref(vitalSign),
      scanParameters: ref(scanParameters),
      conditions: ref(),
      message: ref(),
      disclaimer: ref(),
      pythonBackend: ref(false),
      timeLeft: ref(null),
      user: ref(currentUser),
      idle,
      updateIdle,
      showTutorial: ref(false),
      profileFilled,
      updateProfileFilled,
    };
  },
  mounted() {
    // console.log(newUserManager.currentUser)
    // console.log(newUserManager.currentUser.currentUser)
    // console.log(newUserManager.currentUser.currentUser.getUserSettings())
    // load user / customer form

    setTimeout(async () => {
      let currentUser = newUserManager.currentUser.currentUser;
      if (currentUser) {
        this.form = await currentUser.getUserSettings();
      }
    }, 50);
  },
  async created() {
    await this.getUserType();
    if (window.sessionStorage.userSettings) {
      this.form = JSON.parse(window.sessionStorage.userSettings);
    }
  },
  watch: {
    vsStatus(newValue) {
      if (newValue == "measuring") {
        this.conditions = undefined;
        this.message = undefined;
      }
    },
    devices() {
      if (this.devices[0].text !== "") {
        clearInterval(this.checkDevice);
      }
    },
    sysStatus(status) {
      Logger.info(`Status changed from "${this.sysStatus}" to "${status}".`);
      if (status == "running") {
        this.starting = false;
        this.message = undefined;
      }

      if (status == "standby") {
        this.stopping = false;
        this.message = undefined;
      }
    },
    healthObject: {
      deep: true,
      handler: function () {
        this.vitalSign = newUserManager.currentUser.currentUser.lastVitalSigns;
      },
    },
    async starting(val) {
      if (val) {
        this.checkDevice = setInterval(async () => {
          let devices = await navigator.mediaDevices.enumerateDevices();
          devices = devices.filter((device) => device.kind === "videoinput");
          this.devices = devices.map((device, index) => {
            return { text: device.label, id: device.deviceId, deviceIndex: index };
          });
        }, 1000);
      } else if (!val) {
        this.stopSystem();
      }
    },
    showQR(val) {
      if (val) {
        this.result = newUserManager.currentUser.currentUser.quizAnswer;
        this.addResult();
        if (this.result.lify.length == 0) {
          this.result.lify = ["energy", "sunrise"];
        } else if (this.result.lify.length == 1) {
          if (this.recommendations[this.result.lify[0]] == "caffeine") {
            this.result.lify.push(["sunrise"]);
          } else {
            this.result.lify.push(["energy"]);
          }
        } else {
          if (this.result.lify.every((tea) => this.recommendations[tea] == "caffeine")) {
            this.result.lify = [this.result.lify[0], "sunrise"];
          } else if (this.result.lify.every((tea) => this.recommendations[tea] == "decaffeine")) {
            this.result.lify = [this.result.lify[0], "energy"];
          }
        }
        this.showResult = true;
      } else {
        this.showResult = false;
      }
    },
  },
  data() {
    return {
      detectionResult: undefined,
      aspectRatio: undefined,
      form: undefined,
      userType: undefined,
      quizFinished: false,
      result: undefined,
      showQuiz: false,
      scanFinished: false,
      number: 0,
      cameraIndex: 0,
      devices: [{ text: "", id: "", deviceIndex: 0 }],
      checkDevice: undefined,
      countdown: 3,
      showResult: false,
      showModal: false,
      recommendations: {
        sunrise: "decaffeine",
        sunset: "decaffeine",
        sleep: "decaffeine",
        energy: "caffeine",
        young: "caffeine",
      },
      scanResultId: "",
      showTC: false,
    };
  },
  methods: {
    updateShowTC(value) {
      this.showTC = value;
    },
    uploadedScanResult(id) {
      this.scanResultId = id;
    },
    updateModal(value) {
      this.showModal = value;
    },
    updateConditions(conditions) {
      this.conditions = conditions;
    },
    updateCountdown(countdown) {
      this.countdown = countdown;
    },
    switchCamera() {
      this.cameraIndex = (this.cameraIndex + 1) % this.devices.length;
    },
    async getUserType() {
      setTimeout(() => {
        if (!this.currentUser) return;
        else {
          this.userType = this.currentUser.userSettings.planType;
        }
      }, 2000);
    },
    showGuide() {
      this.showTutorial = !this.showTutorial;
    },
    startMeasurement() {
      // check conditions
      this.vitalSign = null;
      this.scanParameters = null;
      if (newUserManager.currentUser) {
        let currentUser = newUserManager.currentUser.currentUser;
        if (currentUser) {
          currentUser.clearHealth();
        }
      }
      this.vsStatus = "measuring";
      if (newUserManager.currentUser.currentUser.quizAnswer) {
        this.number = newUserManager.currentUser.currentUser.quizAnswer.length + 1;
      }
      this.scanFinished = false;
    },
    updateTimeLeft(newValue) {
      this.timeLeft = newValue;
      // console.log("home-page time left: ", this.timeLeft);
    },
    getTimeLeft() {
      return this.timeLeft;
    },
    finishMeasure() {
      this.vsStatus = "idle";
      this.scanFinished = true;
      resetMovementArray();
    },
    updateDetectionResult(newValue) {
      this.detectionResult = newValue;
    },
    updateScanParameters(newValue) {
      this.scanParameters = newValue;
    },
    getAspectRatio(ar) {
      this.aspectRatio = ar;
    },
    onResult(result) {
      this.detectionResult = result;
    },
    refreshStatus() {
      api.refreshStatus().then((data) => {
        this.flask_fps = data.fps;

        if (data.status == "running") {
          this.starting = false;
        }

        if (data.status == "standby") {
          this.stopping = false;
        }

        if (this.sysStatus !== data.status) {
          Logger.info(`Status changed from "${this.sysStatus}" to "${data.status}".`);
        }

        this.sysStatus = data.status;
      });
    },
    startSystem: function () {
      Logger.info("Start button pressed");

      // this.enableSound();

      this.updateIdle();
      this.starting = true;
      this.stopping = false;
      this.conditions = undefined;
      this.vitalSign = undefined;

      if (this.pythonBackend) {
        api
          .startSystem()
          .then(() => {
            this.refreshStatus();
          })
          .catch((err) => {
            console.error(err);
            alert(err.message);
            this.starting = false;
          });
        return false;
      } else {
        //this.sysStatus = "running";
        return false;
      }
    },
    stopSystem: function () {
      Logger.info("Stop button pressed.");

      this.updateIdle();
      this.stopping = true;
      this.starting = false;
      this.vitalSign = undefined;
      this.conditions = undefined;
      this.detectionResult = undefined;
      this.vsStatus = "idle";
      this.showQuiz = false;
      resetMovementArray();

      if (this.pythonBackend) {
        api.stopSystem().then(() => {
          this.starting = false;
          this.stopping = true;
          this.refreshStatus();
        });
        return false;
      } else {
        this.sysStatus = "standby";
        return false;
      }
    },
    fullScreen() {
      this.$refs.videoContainer.requestFullscreen();
    },
    async removeProfile() {
      await newUserManager.currentUser.currentUser.clearUserSettings();
      newUserManager.currentUser.currentUser.clearQuizAnswers();
      this.form = newUserManager.currentUser.currentUser.userSettings;
      this.updateProfileFilled(false);
      this.vitalSign = null;
      this.scanParameters = null;
      this.quizFinished = false;
      this.showQuiz = false;
      this.number = 0;
      this.scanFinished = false;
      this.showTC = false;
    },
    async updateProfile(form) {
      await newUserManager.currentUser.currentUser.setUserSettings(form);
      this.form = newUserManager.currentUser.currentUser.userSettings;
      this.updateProfileFilled(true);
      this.startMeasurement();
    },
    displayResult() {
      this.quizFinished = true;
      this.number += 1;
    },
    startQuiz() {
      this.showQuiz = true;
    },
    nextCard() {
      this.number += 1;
    },
    addResult() {
      if (this.vitalSign.heartRate > 100) {
        this.result.lify.push("sunset");
      }
      if (this.vitalSign.stress > 4) {
        this.result.lify.push("sunset");
      } else if (this.vitalSign.stress > 2) {
        this.result.lify.push("sleep");
      }
      if (this.vitalSign.generalWellness < 40) {
        this.result.lify.push("energy");
      } else if (this.vitalSign.generalWellness < 80) {
        this.result.lify.push("young");
      }
      if (this.vitalSign.facialSkinAge < 34) {
        this.result.lify.push("young");
      } else {
        this.result.lify.push("sunrise");
      }
    },
  },
  computed: {
    on() {
      return '"' + this.t("button.on") + '"';
    },
    healthObject() {
      if (this.$root.$data.healthObject !== undefined) {
        return "healthObject updated.";
      } else {
        return "no health result";
      }
    },
    radius() {
      if (window.innerWidth < 834) {
        return 48;
      } else {
        return 63.5;
      }
    },
    gap() {
      if (adapter.browserDetails.browser == "safari") {
        return "20px";
      } else {
        return 0;
      }
    },
    showQR() {
      return this.quizFinished && this.scanFinished && this.vitalSign;
    },
    recommend() {
      let teas = this.result.lify;
      if (this.result == undefined) {
        return {
          lify: [],
          fittery: "",
        };
      }
      const sortByFrequency = (array) => {
        var frequency = {};

        array.forEach(function (value) {
          frequency[value] = 0;
        });

        var uniques = array.filter(function (value) {
          return ++frequency[value] == 1;
        });

        return uniques.sort(function (a, b) {
          return frequency[b] - frequency[a];
        });
      };
      teas = sortByFrequency(teas);
      if (teas.length > 2) {
        // find caffeine drink
        const c = this.result.lify.find((tea) => this.recommendations[tea] == "caffeine");
        // find caffeine-free drink
        const dc = this.result.lify.find((tea) => this.recommendations[tea] == "decaffeine");
        teas = [c, dc];
      }
      return {
        lify: teas,
        fittery: this.result.fittery,
      };
    },
  },
  components: {
    FaceDetectionView,
    userProfileInput,
    Quiz,
    Result,
    StartContent,
    LocaleButton,
    ConditionChecking,
    CircularProgress,
    LoadingScreen,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;800&display=swap");

html {
  scroll-behavior: smooth;
}
body {
  background-color: lightgray;
  max-height: 98vh !important;
  min-height: 98vh !important;
  width: 100vw !important;
  overflow: hidden;
}
</style>

<style scoped>
.camera-switch {
  transform: scale(0.8);
  position: absolute;
  bottom: 5%;
  right: 3%;
  z-index: 5;
}

.switch-button {
  background: rgba(255, 255, 255, 0.56);
  border-radius: 20px;
  overflow: hidden;
  width: 200px;
  height: 36px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 1px;
  color: black;
  padding-left: 100px;
  position: relative;
}
.switch-button:before {
  content: v-bind(on);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 600;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  pointer-events: none;
}

.white {
  color: white;
}

.black {
  color: black;
}

.switch-button-checkbox {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}
.switch-button-checkbox:checked + .switch-button-label:before {
  transform: translateX(-100px);
  transition: transform 300ms linear;
  background-color: #a5915c;
}

.switch-button-checkbox + .switch-button-label {
  position: relative;
  display: block;
  user-select: none;
  pointer-events: none;
  line-height: 36px;
}

.switch-button-label:before {
  content: "";
  background: white;
  height: 36px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  color: black;
  border-radius: 20px;
  transform: translateX(0);
  transition: transform 300ms;
}

.switch-button-label-span {
  position: relative;
  color: black;
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  font-weight: 600;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.quick-help-button {
  /* display: inline-block; */
  opacity: 0.7;
  min-width: 40px;
  min-height: 40px;
  vertical-align: middle;
  z-index: 2;
}
.buttons {
  vertical-align: middle;
  position: relative;
  z-index: 1;
}
.buttons-container {
  display: flex;
  position: absolute;
  vertical-align: middle;
  width: 95%;
  top: 40px;
  z-index: 1;
  gap: 20px;
}
.video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}

.vital-sign-hud {
  margin-left: 48px;
  margin-right: 48px;
}
.vsButton {
  display: flex;
  justify-content: center;
}
#lify-logo {
  position: absolute;
  width: 135px;
  top: 80px;
  left: 80px;
}

#panoptic-logo {
  position: absolute;
  width: 280px;
  left: 230px;
  top: 126px;
}

#fittery-logo {
  position: absolute;
  width: 135px;
  height: 135px;
  left: 530px;
  top: 80px;
}

#tda-logo {
  position: absolute;
  left: 698px;
  top: 117px;
}

.system-message {
  margin-top: 20%;
}
.instruction-box {
  position: absolute;
  top: 10%;
  width: calc(100% - 87px);
  font-size: 48px;
  text-align: center;
  color: #ff3535;
  text-shadow: 1px 1px white;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
}
#video-container {
  overflow: hidden;
  z-index: 0;
  height: 98vh;
}
.running-message {
  margin-top: 20%;
  font-size: 48px;
  color: white;
  text-align: center;
  text-shadow: 0px 0px 5px #000000;
}
.disclaimer {
  position: absolute;
  left: 40%;
  right: 40%;
  top: 20px;
  padding: 10px;
  color: black;
  background-color: lightgrey;
  opacity: 0.8;
  font-size: 0.9em;
  text-align: center;
}
.copyright {
  position: relative;
  font-size: 14px;
  bottom: 3%;
  left: 50%;
  transform: translate(-50%, 0);
}
#icon-mobile {
  display: none;
}
.huds-container {
  position: absolute;
  top: 208px;
  width: 100%;
  height: 70%;
  overflow-y: scroll;
  overscroll-behavior: none;
  /* height: fit-content; */
}
.huds-container::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.huds-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.disableBox {
  pointer-events: none;
  opacity: 0.7;
}
.info-bar {
  position: absolute;
  bottom: 5%;
  left: calc(50% - 64px);
}
.info-button {
  width: 150px;
  height: 75px;
  min-height: 50px;
  font-size: large;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  margin-top: auto;
  margin-bottom: auto;
}

.vitals-row {
  margin-left: 3rem;
  margin-right: 3rem;
}

@media (max-width: 2000px) {
  .huds-container {
    top: 500px;
    height: 45%;
  }
  #lify-logo {
    width: 100px;
    top: 40px;
    left: 38px;
  }
  #panoptic-logo {
    width: 200px;
    left: 150px;
    top: 75px;
  }
  #fittery-logo {
    width: 100px;
    height: 100px;
    left: 365px;
    top: 40px;
  }
  #tda-logo {
    width: 140px;
    left: 480px;
    top: 65px;
  }
}

@media (max-width: 940px) {
  #lify-logo {
    width: 70px;
    top: 30px;
    left: 38px;
  }
  #panoptic-logo {
    width: 175px;
    left: 120px;
    top: 55px;
  }
  #fittery-logo {
    width: 70px;
    height: 70px;
    left: 305px;
    top: 30px;
  }
  #tda-logo {
    width: 110px;
    left: 385px;
    top: 47px;
  }
}

@media (max-width: 720px) {
  .buttons {
    right: 20px;
  }

  .huds-container {
    top: 350px;
  }

  .vitals-row {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .info-bar {
    left: calc(50% - 48px);
  }
}
</style>
