<template>
  <div class="quiz" v-for="(content, index) in contents" :key="index">
    <card-content
      v-if="number == index"
      :result="content"
      :number="index"
      @nextQuestion="nextQuestion"
      @addResult="addResult"
      :last="index == contents.length - 1"
    />
  </div>
</template>

<script>
import CardContent from "./card-content.vue";
import { newUserManager } from "../../user/company-user-manager.ts";

export default {
  setup() {},
  props: {
    scanFinished: Boolean,
    number: Number,
  },
  components: {
    CardContent,
  },
  computed: {
    quizAnswer() {
      return newUserManager.currentUser.currentUser.quizAnswer;
    },
  },
  emits: ["displayResult", "nextCard"],
  data() {
    return {
      contents: [
        ['', "sunrise", "sunset", ],
        ['', '', "sleep"],
        ['energy', 'sunrise', 'young'],
      ],
      result: {
        lify: [],
        fittery: "",
      },
    };
  },
  watch: {
    number() {
      if (this.number == this.contents.length) {
        newUserManager.currentUser.currentUser.setQuizAnswers(this.result);
        this.$emit("displayResult");
      }
    },
  },
  methods: {
    nextQuestion() {
      this.$emit("nextCard");
    },
    addResult(newResult) {
      if (newResult) {
        this.result.lify.push(newResult);
      } 
    },
  },
};
</script>

<style scoped>
.quiz {
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  top: 1050px;
  max-height: calc(100vh - 440px);
  overflow: scroll;
  opacity: 0.9;
}

@media (max-width: 2000px) {
  .quiz {
    top: 430px;
    left: 0px;
    width: 100%;
  }
}
</style>